import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import HeaderExternalLinks from "../components/header-external-links";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Text = makeShortcode("Text");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Text sx={{
      fontSize: [4, 5, 6],
      fontWeight: `bold`,
      color: `heading`
    }} mdxType="Text">
  Hello! I'm Richban. 👋
    </Text>
    <p></p>
    <Text sx={{
      fontSize: [3, 4, 4, 5]
    }} mdxType="Text">
I'm a <a style={{
        color: `#FFB454`,
        fontWeight: `bold`,
        textDecoration: `none`
      }}>software engineer</a> specialized in <a style={{
        color: `#59C2FF`,
        fontWeight: `bold`,
        textDecoration: `none`
      }}>data engineering</a> and building scalable
applications powered by <a style={{
        color: `#C2D94C`,
        fontWeight: `bold`,
        textDecoration: `none`
      }}>machine learning.</a>
    </Text>
    <p></p>
    <HeaderExternalLinks mdxType="HeaderExternalLinks" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      